<template>
  <div class="more-options">
    <cta-button
      v-if="cta"
      v-bind="cta"
      :disabled="cta?.disabled ?? isDisabled"
      :action="open"
      :ref="(r) => refHandler(r, (e) => (triggerElem = e))"
    />
    <icon-toggle-button
      v-else-if="icon"
      v-bind="icon"
      :action="open"
      :is-disabled="icon?.isDisabled ?? isDisabled"
      :ref="(r) => refHandler(r, (e) => (triggerElem = e))"
    />
    <app-teleport v-if="isOpen">
      <cta-options
        v-if="ctaOptions"
        :options="
          ctaOptions.map((option) => {
            if (option === 'seperator' || !('action' in option)) {
              return option;
            }
            return {
              ...option,
              action: (e) => {
                option.action(e);
                close();
              },
            };
          })
        "
        :ref="(r) => refHandler(r, (e) => (contentElem = e))"
        v-click-outside="{
          callback: close,
          enableCloseOnEsc: true,
        }"
      />
      <icon-toggle-options
        v-else-if="iconOptions"
        :options="
          iconOptions.map((option) => {
            if (option === 'seperator' || !('action' in option)) {
              return option;
            }
            return {
              ...option,
              action: (e) => {
                option.action(e);
                close();
              },
            };
          })
        "
        :ref="(r) => refHandler(r, (e) => (contentElem = e))"
        v-click-outside="{
          callback: close,
          enableCloseOnEsc: true,
        }"
      />
    </app-teleport>
  </div>
</template>

<script setup lang="ts">
import { ref, watch } from "vue";

import { AppTeleport } from "@horizon56/bootstrap";
import { vClickOutside } from "@horizon56/directives/click-outside";
import { refHandler, stickElement, unstickElement } from "@horizon56/utils";

import CtaButton, { type CtaButtonProps } from "@/components/buttons/cta-button.vue";
import CtaOptions, { type Option as CtaOption } from "@/components/buttons/cta-options.vue";
import IconToggleButton, { type IconToggleButtonProps } from "@/components/buttons/icon-toggle-button.vue";
import IconToggleOptions, { type Option as IconToggleOption } from "@/components/buttons/icon-toggle-options.vue";

export type Props = {
  cta?: Omit<CtaButtonProps, "action">;
  icon?: Omit<IconToggleButtonProps, "action">;
  ctaOptions?: CtaOption[];
  iconOptions?: IconToggleOption[];
  isDisabled?: boolean;
  direction?: "upwards" | "downwards";
};

const props = withDefaults(defineProps<Props>(), {
  icon: () => ({
    icon: "more_vert",
    size: "medium",
  }),
  direction: "downwards",
});

const emits = defineEmits<{ (e: "isOpen", a: boolean): void }>();

const isOpen = ref(false);
const triggerElem = ref<HTMLElement | undefined>();
const contentElem = ref<HTMLElement | undefined>();
const stickId = ref("");

const open = () => {
  if (props.cta?.disabled || props.icon?.isDisabled) {
    return;
  }
  isOpen.value = true;
};

const close = () => (isOpen.value = false);

watch(
  () => isOpen.value && !!contentElem.value && !!triggerElem.value,
  (is) => {
    if (!triggerElem.value || !contentElem.value) {
      return;
    }
    if (is) {
      stickElement({
        stickTo: triggerElem.value,
        element: contentElem.value,
        align: "left",
        stick: "right",
        placement: props.direction === "upwards" ? "top" : "bottom",
        disapaired: close,
        ...(props.iconOptions && {
          placement: "top",
          align: "center",
        }),
      });
    } else if (stickId.value) {
      unstickElement(stickId.value);
      stickId.value = "";
    }
  },
);

watch(
  () => isOpen.value,
  (is) => emits("isOpen", is),
);
</script>

<style lang="scss" scoped>
.more-options {
  display: inline-flex;
}
</style>
